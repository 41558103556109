<template>
   <div class="about">
      <section class="service_promo_area" style="padding-top:50px;padding-bottom:50px">
         <div class="container" style="text-align: justify;">
            <h1> Privacy Policy <router-link to="/">TrikCPNS.com</router-link></h1>
            <p>
               <b>Kebijakan Privasi website TrikCPNS.com adalah penjelasan terkait data dan informasi pribadi Pengguna website TrikCPNS.com, meliputi:</b>
               <br/>
            <ol>
               <li>Hukum dan Peraturan yang berlaku;</li>
               <li>Perolehan dan Perlindungan Data Pengguna;</li>
               <li>Penggunaan, Penyimpanan, Pemanfaatan, dan Pengolahan Data Pengguna;</li>
               <li>Penghapusan Data Pengguna;</li>
               <li>Pembatasan Tanggung Jawab TrikCPNS.com;</li>
               <li>Tentang Cookies;</li>
               <li>Disclaimer</li>
            </ol>
            </p>
            <p>
               Website TrikCPNS.com berhak memperbaharui Kebijakan Privasi ini dari waktu ke waktu agar tetap relevan dan sesuai dengan perkembangan teknologi saat ini, hukum yang berlaku, praktik bisnis TrikCPNS.com dan kebutuhan para pengguna TrikCPNS.com. Jika ada perubahan yang dibuat pada Kebijakan Privasi ini, TrikCPNS.com akan memberikan keterangan "Tanggal Perubahan Terakhir (Update)". TrikCPNS.com juga menghimbau kepada para Pengguna TrikCPNS.com agar menegcek dan melihat Kebijakan Priavsi ini secara berkala sehingga tetap mendapatkan informasi yang update tentang bagaimana TrikCPNS.com mengelola data dan informasi pribadi Anda. Dengan menggunakan Layanan yang ada pada website TrikCPNS.com, Pengguna dianggap memberikan persetujuan eksplisit atas Kebijakan Privasi dan dengan terus menggunakan Layanan setelah Kebijakan Privasi serta Pengguna dianggap telah menyetujui Kebijakan Privasi yang diperbarui.                    
            </p>
            <b>1.	HUKUM DAN PERATURAN YANG BERLAKU</b>
            <p>
               TrikCPNS.com tunduk dan patuh terhadap semua peraturan perundang-undangan dan kebijakan pemerintah Republik Indonesia (RI) yang berlaku, termasuk yang mengatur tentang informasi dan transaksi elektronik, penyelenggaraan sistem elektronik, dan perlindungan data pribadi para Pengguna; termasuk segala peraturan pelaksana dan peraturan perubahan dari peraturan-peraturan tersebut yang mengatur dan melindungi penggunaan data dan informasi penting para Pengguna.                        
            </p>
            <p>
               <b>2.	PEROLEHAN DAN PERLINDUNGAN DATA PENGGUNA</b>
            <p>
               Dalam memperoleh dan melindungi data para pengguna, maka diberikan penjelasan dan rincian sebagai berikut:                        
            <ol>
               <li>
                  TrikCPNS.com berhak meminta data dan informasi Pengguna, yaitu :
                  <br/>
                  <br/>
                  <ol type="a">
                     <li>Informasi terkait akun TrikCPNS.com : 1) Nama lengkap; 2) Username; 3) E-mail; 4) Nomor Telepon/HP; 5) Alamat dan/atau lokasi; 6) Profil, antara lain Tanggal lahir, Jenis kelamin; dan 7) Informasi Transaksi, Keuangan, dan/atau Perbankan</li>
                     <li>Perilaku Pengguna di website TrikCPNS.com dan/atau selama menggunakan Layanan Informasi pilihan produk, fitur, dan layanan yang ada pada website: 1) Informasi preferensi dan minat Pengguna; 2) Informasi pengalaman Pengguna; 3) Informasi foto dan media pada perangkat Pengguna; 4) Informasi geo location secara real time; 5) Informasi hasil survei yang dikirimkan oleh TrikCPNS.com atau atas nama TrikCPNS.com; 6) Histori atau Rekam jejak Pengguna (log) yang meliputi alamat IP perangkat, tanggal dan waktu akses, jenis web browser, laman website yang dilihat, fitur aplikasi, proses kerja aplikasi dan aktivitas sistem lainnya, dan/atau situs atau situs layanan dari pihak ke-3 yang digunakan sebelum berinteraksi dengan situs; dan 7) Informasi lain dari Pengguna yang tidak terbatas pada aktivitas pendaftaran, login, transaksi, dan lain sebagainya</li>
                     <li>Informasi yang berasal dari pihak ketiga yang bekerja sama dengan TrikCPNS.com seperti partner, mitra, agen, vendor, konsultan, penasihat profesional, anak perusahaan, perusahaan afiliasi, dan pihak lain dapat kami kumpulkan sehubungan dengan tujuan dari keterlibatan pihak ke-3.
                        <br/>
                        <br/>
                        Berdasarkan poin-poin pada informasi huruf a sampai c di atas, maka para Pengguna melepaskan hak atas klaim, kerugian, tuntutan, dan gugatan yang mungkin terjadi atas perolehan, penyimpanan, penggunaan, pemanfaatan, dan/atau pengungkapan informasi, termasuk informasi pribadi, dalam Sistem website TrikCPNS.com.                                        
                     </li>
                  </ol>
               </li>
               <br/>
               <li>Pengguna website TrikCPNS.com dengan ini memberikan persetujuan secara eksplisit atas perolehan, penyimpanan, penggunaan, pemanfaatan, dan/atau pengungkapan data dan informasi pribadi yang Pengguna berikan (input) ke dalam Sistem TrikCPNS.com, termasuk data pribadi Pengguna, pilihan paket latihan, chat dan percakapan dalam sistem TrikCPNS.com dan dengan demikian Pengguna menyatakan bahwa pemberian persetujuan atas data dan informasi pribadi tersebut dilakukan tanpa paksaan, dalam kondisi baik, dan dengan keadaan sadar, tanpa ada paksaan dari pihak manapun dimana persetujuan tersebut Pengguna berikan saat membuat akun TrikCPNS.com ("Persetujuan Pengguna").</li>
               <br/>
               <li>Dengan Persetujuan Pengguna, maka Pengguna dengan ini menyatakan seluruh data dan informasi tersebut adalah milik TrikCPNS.com. kemudian, untuk menghindari keragu-raguan, data pribadi Pengguna, baik yang dimasukkan ke dalam Sistem TrikCPNS.com maupun yang tidak dimasukkan ke dalam Sistem TrikCPNS.com, adalah milik Pengguna sebagai bagian dari hak pribadi secara utuh.</li>
               <br/>
               <li>Pengguna website TrikCPNS.com dengan ini menyatakan bahwa data pribadi yang Pengguna berikan (input) ke dalam Sistem TrikCPNS.com (termasuk dokumen yang diunggah dan/atau diberikan kepada TrikCPNS.com) adalah asli, akurat, benar, lengkap, tidak menyesatkan, terbaru (mutakhir), dan merupakan hak pribadi Pengguna dan/atau dirinya berwenang untuk menyampaikan data pribadi Pengguna untuk menyampaikannya ke dalam Sistem TrikCPNS.com.</li>
               <br/>
               <li>TrikCPNS.com berkewajiban melindungi segala informasi yang diberikan Pengguna pada saat Pengguna menggunakan seluruh layanan yang terdapat pada website TrikCPNS.com, termasuk menjaga kerahasiaan, keutuhan, dan ketersediaan data pribadi yang dikelolanya.</li>
               <br/>
               <li>Dalam menjalankan pengelolaan data, TrikCPNS.com secara berkala berhak meminta authentifikasi dan pemutakhiran data pribadi Pengguna, sehingga data dan informasi Pengguna akurat, lengkap, dan terbaru, termasuk memberhentikan Layanan sementara dan/atau memberhentikan Layanan tetap, dalam hal Pengguna belum melakukan authentifikasi dan pemutakhiran (pembaruan) data pribadi Pengguna.</li>
               <br/>
               <li>Pengguna berhak dari waktu ke waktu melengkapi dan melakukan pemutakhiran (pembaruan) data pribadi Pengguna ke dalam system TrikCPNS.com</li>
               <br/>
               <li>Pengguna dengan ini memahami bahwa pemanfaatan beberapa layanan fitur tertentu dalam website TrikCPNS.com memerlukan proses authentifikasi data pribadi lebih lanjut dan tunduk pada Aturan Penggunaan website TrikCPNS.com serta syarat dan ketentuan layanan fitur tertentu di TrikCPNS.com tersebut. Pengguna dengan ini menegaskan bahwa setuju secara eksplisit atas perolehan, penyimpanan, penggunaan, pemanfaatan, dan/atau pengungkapan data pribadi yang Pengguna sampaikan (input) ke dalam Sistem TrikCPNS.com dan fitur tertentu di TrikCPNS.com tersebut, serta menyatakan bahwa pemberian penggunaan data pribadi tersebut dilakukan tanpa paksaan, dalam kondisi baik, dan dengan keadaan sadar tanpa ada paksaan dari pihak manapun.</li>
            </ol>
            </p>
            <p>
               <b>3. PENGGUNAAN, PENYIMPANAN, PEMANFAATAN, DAN PENGOLAHAN DATA PENGGUNA</b>
            <p>
               Dalam hal penggunaan, penyimpanan, pemanfaatan dan pengolahan data pengguna, maka Trikcpns.com berhak :
            <ol start="1" type="1">
               <li>Menggunakan data dan informasi pribadi Pengguna untuk meningkatkan dan menjamin mutu dan pelayanan yang ada pada website TrikCPNS.com sesuai dengan ketentuan perundang-undangan yang berlaku dan berdasarkan Persetujuan Pengguna.</li>
               <br/>
               <li>Menggunakan informasi umum dan penggunaan layanan yang telah dikumpulkan untuk verifikasi data Pengguna.</li>
               <br/>
               <li>Menggunakan informasi transaksi Penggun untuk melakukan monitoring dan mengetahui pola transaksi Pengguna, serta untuk keperluan administrasi dan kepentingan penyelidikan atau kepentingan lainnya sebagaimana yang diwajibkan oleh aturan perundang-undangan.</li>
               <br/>
               <li>Menggunakan dan menyimpan informasi nama bank yang di dalamnya termasuk nama dan nomor rekening, nama dan/atau nomor kartu kredit untuk memastikan pembayaran/penerimaan oleh/dari Pengguna TrikCPNS.com.</li>
               <br/>
               <li>Menggunakan informasi pribadi Pengguna secara keseluruhan untuk keperluan &ldquo;<em>know-your-customer</em>&rdquo; serta pemprosesan data internal untuk memastikan bahwa layanan apda website berfungsi secara teknis.</li>
               <br/>
               <li>Mengumpulkan Informasi Pengguna untuk menghindari dari pelanggaran peraturan perundang-undangan yang berlaku, penyelidikan dan penyelesaian sengketa, dan kegiatan ilegal lainnya, termasuk jika ada perselisihan dengan pihak ketiga.</li>
               <br/>
               <li>
                  Menggunakan, memanfaatkan, dan mengungkapkan data dan informasi pribadi Pengguna berdasarkan Persetujuan Pengguna yang telah disampaikan sebelumnya, yang telah Pengguna berikan (input) pada saat perolehan data pribadi, kecuali terjadi beberapa kondisi dan situasi sebagai berikut, antara lain:
                  <br/>
                  <br/>
                  <ol start="1" type="a">
                     <li>diwajibkan atau diminta oleh institusi yang berwenang berdasarkan ketentuan hukum yang berlaku, perintah resmi dari Pengadilan, somasi, dan/atau perintah resmi dari instansi atau aparat yang bersangkutan, termasuk namun tidak terbatas pada perselisihan, penyelidikan, penyidikan, proses hukum dan proses penyelesaian sengketa antara TrikCPNS.com dengan Pengguna, antar-Pengguna, dan Pengguna dengan pihak lainnya serta kegiatan ilegal lainnya;</li>
                     <br/>
                     <li>diwajibkan atau diminta oleh institusi perbankan, keuangan yang berwenang, dan/atau pihak ke-3 berdasarkan permintaan resmi dan ketentuan hukum yang berlaku;</li>
                     <br/>
                     <li>untuk memproses segala bentuk aktivitas Pengguna yang termasuk namun tidak terbatas pada memproses transaksi, verifikasi pembayaran, pemilihan paket produk latihan dan lain &ndash; lain;</li>
                     <br/>
                     <li>TrikCPNS.com dapat menjalin bekerja sama dengan perusahaan da/ataun pihak ketiga lainnya yang dapat memfasilitasi atau memberikan bantuan dalam pengembangan aplikasi dan layanan-layanan tertentu untuk dan/atau atas nama website TrikCPNS.com, meliputi: 1) Memberikan bantuan Pengguna; 2) Melaksanakan layanan-layanan terkait dengan Aplikasi (termasuk namun tidak terbatas pada layanan pemeliharaan, pengelolaan database, analisis dan penyempurnaan fitur-fitur Aplikasi; 3) Membantu TrikCPNS.com dalam menganalisa bagaimana Aplikasi dan Layanan digunakan serta bagaimana pengembangannya; atau 4) Membantu penasihat profesional dan auditor eksternal TrikCPNS.com, termasuk penasihat hukum, penasihat keuangan, dan konsultan-konsultan. Para pihak ketiga ini hanya memiliki akses atas Informasi Pribadi Pengguna untuk melakukan tugas-tugas tersebut untuk dan/atau atas nama TrikCPNS.com dan secara kontraktual terikat untuk tidak mengungkapkan atau menggunakan Informasi Pribadi tersebut untuk tujuan lain apapun;</li>
                     <br/>
                     <li>Dalam pengungkapan informasi, TrikCPNS.com dapat menginformasikan sebagian informasi pribadi Pengguna sehingga informasi tersebut menjadi informasi yang tidak dapat mengidentifikasi Pengguna secara keseluruhan (data agregat/data anonim) yang ditujukan untuk pihak ketiga yang digunakan untuk pengembangan Aplikasi, pengelolaan database, analisa atau peningkatan layanan, promosi dan periklanan;</li>
                     <br/>
                     <li>TrikCPNS.com dapat mengkombinasikan Informasi Pribadi Pengguna dengan informasi-informasi lain sedemikan rupa sehingga informasi tersebut tidak lagi ter-asosiasi dengan Pengguna, kemudian mengungkapkan informasi yang telah dikombinasikan tersebut kepada pihak ketiga, untuk tujuan-tujuan seperti pada poin e;</li>
                     <br/>
                     <li>TrikCPNS.com dapat mengkombinasikan Informasi Pribadi Pengguna dengan informasi-informasi lain sedemikan rupa sehingga informasi tersebut tidak lagi ter-asosiasi dengan Pengguna, kemudian mengungkapkan informasi yang telah dikombinasikan tersebut kepada pihak ketiga, untuk tujuan-tujuan seperti pada poin e;</li>
                     <br/>
                     <li>TrikCPNS.com dapat mengungkapan informasi Pengguna kepada anak perusahaan, afiliasi, mitra, partner, mitra, agen, dan vendor TrikCPNS.com untuk membantu memberikan layanan atau pengelolaan data;</li>
                     <br/>
                     <li>Jika terjadi keadaan darurat yang mengancam kehidupan, kesehatan atau keselamatan seseorang;</li>
                     <br/>
                     <li>Jika diperlukan untuk tujuan yang jelas untuk kepentingan Pengguna dan Persetujuan Pengguna tidak dapat diperoleh tepat waktu; atau yang mungkin diperlukan atau diizinkan oleh hukum.</li>
                     <br/>
                  </ol>
               </li>
               <li>
                  TrikCPNS.com berhak menggunakan, menyimpan, memanfaatkan, dan mengungkapkan data pribadi pengguna berdasarkan Persetujuan Pengguna yang telah Pengguna berikan pada saat perolehan data pribadi untuk:
                  <br/>
                  <br/>
                  <ol start="1" type="a">
                     <li>Menyediakan Layanan dan/atau Penawaran Pihak Ketiga, diantaranya adalah : 1) untuk keperluan yang terkait dengan pembayaran, penagihan, aktivasi, penyediaan, pemeliharaan, dukungan, pemecahan masalah, penyelesaian sengketa, penonaktifan, penggantian, peningkatan atau pembaharuan Layanan tertentu; 2) untuk lebih memahami, menganalisis, dan memprediksi preferensi dan minat Pengguna, dan menggabungkan informasi tersebut dengan informasi lain untuk menyesuaikan pengalaman Pengguna; 3) untuk memfasilitasi akses Pengguna dan penggunaan Layanan dan / atau Penawaran Pihak Ketiga; 4) untuk mengirim Penawaran berlangganan, pesan dan posting dalam Layanan atas nama administrator Saluran atau Akun Resmi; 5) untuk menggabungkan informasi tersebut dengan informasi yang diperoleh dari sumber lain (termasuk Penawaran Pihak Ketiga) sehubungan dengan penyediaan Layanan; dan 6) untuk mengelola atau menanggapi pertanyaan Pengguna;</li>
                     <br/>
                     <li>Melakukan pengembangan layanan atau fitur baru dan meningkatkan Layanan yang ada serta memberikan Pengguna informasi tentang konten pihak ketiga atau produk terkait, layanan dan perangkat lunak termasuk untuk berkomunikasi dengan Pengguna tentang berbagai cara yang mereka gunakan, misalnya untuk menyediakan atau mengirim kepada Pengguna: 1) peningkatan atau pembaruan, atau pemberitahuan tentang peningkatan atau pembaruan, dari Layanan atau konten pihak ketiga atau produk, layanan dan perangkat lunak terkait; 2) pemberitahuan promosi, kontes, penawaran, dan acara mendatang; dan 3) informasi pribadi, survei, materi pemasaran, iklan atau konten yang disesuaikan;</li>
                     <br/>
                     <li>Mengumpulkan, menggunakan dan mengungkapkan untuk kegiatan promosi dan periklanan yang meliputi namun tidak terbatas pada pengidentifikasi iklan, termasuk yang disediakan oleh platform perangkat lunak lain, atau pengenal serupa, untuk memfasilitasi TrikCPNS.com dalam melakukan analitik atau penyediaan konten promosi atau informasi lain yang mungkin relevan bagi Pengguna;</li>
                     <br/>
                     <li>Mengelola dan mengembangkan bisnis dan operasional TrikCPNS.com, misalnya: 1) untuk keperluan statistik; 2) untuk mendeteksi, memantau, menyelidiki, mengurangi atau mencegah penipuan dan masalah teknis atau keamanan atau untuk melindungi properti dari TrikCPNS.com; 3) untuk memungkinkan kelangsungan bisnis dan operasi pemulihan bencana; 4) untuk mendapatkan layanan hukum, untuk mencari nasihat hukum dan / atau untuk menegakkan hak hukum TrikCPNS.com atau hak hukum dari anggota lain dari TrikCPNS.com;&nbsp;</li>
                     <br/>
                     <li>Memenuhi persyaratan hukum dan peraturan dan untuk menanggapi situasi darurat, misalnya: 1) untuk menanggapi perintah pengadilan, somasi atau permintaan yang sah atau penegakan hukum lainnya atau proses hukum; 2) untuk memberikan bantuan darurat dalam situasi yang dapat mengancam kehidupan atau keselamatan fisik Anda atau orang lain; atau</li>
                     <br/>
                     <li>Kepentingan internal TrikCPNS.com untuk pengembangan bisnis, produk, mutu, dan pelayanan termasuk kegiatan promosi, studi, riset, dan kerja sama TrikCPNS.com dengan pihak ketiga lainnya;</li>
                     <br/>
                     <li>Kepentingan TrikCPNS.com, afiliasi TrikCPNS.com, untuk pengembangan bisnis, produk, mutu, dan pelayanan termasuk kegiatan promosi, studi, riset, dan kerja sama TrikCPNS.com dengan pihak ketiga lainnya;</li>
                     <br/>
                     <li>Kepentingan TrikCPNS.com untuk memenuhi tujuan Transaksi atas nama TrikCPNS.com, antara lain dalam hal penjualan semua atau sebagian aset TrikCPNS.com termasuk setiap pembelian, penjualan, penyewaan, merger atau amalgamasi atau akuisisi, pembuangan atau pembiayaan TrikCPNS.com, afiliasi TrikCPNS.com</li>
                     <br/>
                     <li>Tujuan lain yang disampaikan dari waktu ke waktu oleh TrikCPNS.com kepada Pengguna, serta diizinkan atau diwajibkan oleh hukum dan peraturan yang berlaku.</li>
                     <br/>                            
                  </ol>
               </li>
               <li>TrikCPNS.com berhak menyimpan data dan informasi pribadi Pengguna untuk memenuhi tujuan Layanan, tujuan Kebijakan Privasi ini, tujuan peraturan perundang-undangan dan hukum yang berlaku, termasuk memproses, mentransfer, dan/atau menyimpan data dan informasi para Pengguna, baik di dalam wilayah Republik Indonesia maupun di luar wilayah Republik Indonesia, dengan memperhatikan Regulasi, termasuk peraturan perundang-undangan dan kebijakan Privasi di negara mana data dan informasi para Pengguna diproses, ditransfer, dan/atau disimpan.</li>
               <br/>
               <li>TrikCPNS.com, afiliasi TrikCPNS.com.com, dan pihak ketiga yang bekerja sama dengan &nbsp;TrikCPNS.com.com, dalam menyediakan Layanan berhak memanfaatkan dan mengolah data dan informasi pribadi Pengguna untuk memenuhi tujuan Layanan, tujuan Kebijakan Privasi ini, tujuan peraturan perundang-undangan dan hukum yang berlaku.&nbsp;</li>
               <br/>
               <li>TrikCPNS.com berhak memanfaatkan dan mengolah data dan informasi para Pengguna untuk memenuhi tujuan Transaksi atas nama TrikCPNS.com, antara lain dalam hal penjualan semua atau sebagian aset TrikCPNS.com termasuk setiap pembelian, penjualan, penyewaan, merger atau akuisisi, pembuangan atau pembiayaan TrikCPNS.com, dan/atau afiliasi TrikCPNS.com.</li>
               <br/>
               <li>TrikCPNS.com dalam pemanfaatan dan pengolahan data, termasuk transmisi data, sesuai dengan tujuan Kebijakan Privasi ini akan menerapkan keamanan sewajarnya, sesuai dengan peraturan perundang-undangan dan hukum yang berlaku, termasuk memberikan enkripsi pada pemanfaatan dan pengolahan data tersebut.</li>
               <br/>
            </ol>
            </p>
            <p><b>4.	PENGHAPUSAN DATA</b>
            <p>
            <ol start="1" type="1">
               <li>
                  Atas beragam informasi promo terbaru dan penawaran eksklusif, Pengguna dapat:
                  <br/>
                  <br/>
                  <ol start="1" type="a">
                     <li>berhenti berlangganan dengan tidak menggunakan website TrikCPNS.com&nbsp;</li>
                     <li>melakukan pengaturan notifikasi &quot;Promo&quot; pada aplikasi TrikCPNS.com.</li>
                  </ol>
               </li>
               <br/>
               <li>Pengguna dapat menarik Persetujuan Pengguna berikan terkait dengan penggunaan data dan informasi pribadi Pengguna, termasuk berhenti menggunakan, mengakses Layanan, dan/atau menutup akun dengan memperhatikan ketentuan peraturan Perungdang-Undangan yang berlaku (termasuk ketentuan retensi yang diwajibkan oleh perundang-undangan yang berlaku). Pengguna dengan ini menyatakan bahwa Pengguna memahami konsekuensi dari penarikan Persetujuan Pengguna, termasuk namun tidak terbatas pada tidak lagi dapat menikmati Layanan.</li>
               <br/>
               <li>
                  Pengguna dapat mengajukan penghapusan data kepada TrikCPNS.com melalui pnegiriman email admin/kontak TrikCPNS.com dengan melampirkan:<br/>
                  <br/>
                  <ol start="1" type="a">
                     <li>bukti diri yang sah (KTP/SIM/PASPOR) sesuai dengan identitas pribadinya di halaman akun TrikCPNS.com; dan/atau</li>
                     <li>alasan permintaan penghapusan</li>
                  </ol>
               </li>
               <br/>
               <li>TrikCPNS.com menyetujui penghapusan data yang diajukan oleh Pengguna jika telah memenuhi ketentuan pada angka 3 di atas dan telah memenuhi hak dan kewajibannya di TrikCPNS.com, dalam waktu yang wajar.</li>
               <br/>
               <li>TrikCPNS.com atas permintaan Pengguna dapat mengumpulkan data dan informasi Pengguna dibuat tidak dikenali.</li>
            </ol>
            </p>
            <p><b>5.	PEMBATASAN TANGGUNG JAWAB TRIKCPNS.COM</b>
            <p>
            <ol start="1" type="1">
               <li>Pengguna bertanggung jawab atas keamanan dan mitigasi pelanggaran atas akun TrikCPNS.com Pengguna sendiri, seperti menerapkan pengamanan yang tepat, membatasi akses, membuat kata sandi yang kuat, menjaga kata sandi, menjaga <em>one time password</em> (&quot;OTP&quot;).</li>
               <br/>
               <li>TrikCPNS.com tidak bertanggung jawab atas pertukaran dan pemberian data dan informasi pribadi Pengguna yang dilakukan sendiri oleh Pengguna, termasuk yang dilakukan antar-Pengguna. Termasuk dalam ketentuan ini adalah setiap dan segala kesalahan Pengguna yang mengakibatkan kebocoran data Pengguna.</li>
               <br/>
               <li>TrikCPNS.com bertanggung jawab atas Sistem TrikCPNS.com, termasuk perlindungan serta pengamanan rahasia data pribadi, termasuk memberitahukan Pengguna dalam hal terjadi kegagalan dalam perlindungan data pribadi melalui sekurang-kurangnya melalui e-mail Pengguna yang terdaftar pada Sistem TrikCPNS.com dan melaporkan kepada aparat penegak hukum atau Instansi Pengawas dan Pengatur Sektor terkait. Dalam hal terjadi kebocoran data, pihak yang bertanggung jawab untuk menginformasikan kepada Pengguna terkait kegagalan dalam perlindungan data tersebut adalah pihak atau institusi dimana data tersebut diproses berdasarkan Kebijakan Privasi ini.</li>
               <br/>
               <li>TrikCPNS.com tidak bertanggung jawab atas keaslian, keautentikan, kebenaran, keakuratan, kelengkapan data pribadi yang dimasukkan oleh Pengguna ke dalam Sistem TrikCPNS.com.</li>
               <br/>
               <li>Dengan memberikan Persetujuan Pengguna, Pengguna melepaskan hak atas klaim, kerugian, tuntutan, dan gugatan yang mungkin terjadi atas perolehan, penyimpanan,penggunaan, pemanfaatan, dan/atau penggungkapan data, termasuk data pribadi, dalam Sistem TrikCPNS.com.</li>
               <br/>
               <li>Dalam hal Pengguna adalah anak belum dewasa, TrikCPNS.com tidak bertanggung jawab atas input data pribadi Pengguna anak dan menganjurkan agar orang tua/wali anak memantau penggunaan internet anak, sehingga pemberian data pribadi Pengguna anak diberikan dan/atau dalam pengawasan orang tua/wali sebagai pihak yang berwenang.</li>
               <br/>
               <li>TrikCPNS.com tidak bertanggung jawab atas kebocoran data yang terjadi diakibatkan dan/atau terjadi selama karena Keadaan Memaksa. Keadaan Memaksa meliputi antara lain (i) pemogokan, penutupan perusahaan, dan aksi industrial lain; (ii) huru-hara, kerusuhan, invasi, serangan atau ancaman teroris, perang (baik yang dinyatakan atau tidak) atau ancaman persiapan perang; (iii) kebakaran, ledakan, badai, banjir, gempa bumi, epidemi atau bencana alam lain; (iv) tidak tersedianya atau terganggunya jaringan telekomunikasi, informatika dan/atau listrik; (v) terdapatnya kegagalan sistem yang diakibatkan pihak ketiga yang terjadi di luar wewenang TrikCPNS.com; (vi) tidak berfungsinya sistem dan/atau jaringan perbankan, (vii) tindakan, putusan, undang-undang, peraturan atau pembatasan yang diterbitkan pemerintah. Dalam hal terjadi Keadaan Memaksa, TrikCPNS.com memberitahukan Pengguna paling lambat 14 (empat belas) Hari Kalendar setelah terjadinya Keadaan tersebut dan berusaha dengan kemampuan komersial terbaiknya memenuhi kewajiban TrikCPNS.com berdasarkan Kebijakan Privasi ini.</li>
               <br/>
            </ol>
            </p>
            <p><b>6.	COOKIES</b>
            <p>                        
            <ol start="1" type="1">
               <li>Cookies adalah tempat penyimpanan data yang kecil terdapat pada komputer atau perangkat lain seperti ponsel pintar atau tablet, pada saat Pengguna menelusuri dan/atau mengunjungi Situs TrikCPNS.com.</li>
               <br/>
               <li>Komputer atau perangkat lain Pengguna akan secara otomatis menerima Cookies pada saat Pengguna menelusuri dan/atau mengunjungi Situs TrikCPNS.com serta saat Pengguna menggunakan layanan TrikCPNS.com (&quot;Penggunaan Cookies&quot;). Namun, Pengguna dapat menentukan pilihan untuk melakukan modifikasi atau memilih untuk menolak Penggunaan Cookies melalui preferensi pengaturan web browser Pengguna.</li>
               <br/>
               <li>TrikCPNS.com menggunakan Google Analytics (&quot;Fitur&quot;), dimana data yang diperoleh dari penggunaan Fitur tersebut meliputi, IP address Pengguna, jenis perangkat Pengguna, dan lain &ndash; lain (&quot;Data&quot;). Data tersebut digunakan oleh TrikCPNS.com untuk pengembangan Situs dan Konten TrikCPNS.com serta pengiklanan. Pengguna dapat memilih untuk tidak terakses oleh Fitur dilakukan dengan cara mengunduh Google Analytics Opt-Out Add-on pada web browser Pengguna.</li>
               <br/>
               <li>TrikCPNS.com dapat memberikan data dan informasi Pengguna yang berasal dari dari Penggunaan Cookies kepada pihak ketiga, seperti data lokasi, pengidentifikasi iklan, atau alamat email yang digunakan untuk segmentasi periklanan, termasuk namun tidak terbatas pada untuk kebutuhan pemasaran dan periklanan, dimana data dan informasi tersebut tidak dapat diidentifikasi secara pribadi.</li>
               <br/>
               <li>Pengguna dapat melakukan kontrol terhadap Penggunaan Cookies melalui preferensi pengaturan web browser Pengguna yaitu dengan modifikasi atau memilih untuk menolak Penggunaan Cookies. Namun dengan melakukan pengaturan tersebut, kinerja pelayanan pada saat akses ke Situs TrikCPNS.com dapat terpengaruh, seperti fungsi dan halaman tertentu pada Situs TrikCPNS.com tidak dapat bekerja optimal untuk layanan kepada Pengguna.</li>
               <br/>
            </ol>
            </p>
            <p>
               <b>7.	DISCLAIMER</b>
            <p>Secara singkatnya, disclaimer website TrikCPNS.com adalah sebagai berikut :
            <ol>
               <li>trikcpns.com merupakan platform yang hanya menyediakan soal-soal, kisi-kisi, tips dan trik, statistik dan pembahasan sebagai latihan para peserta yang akan atau telah mendaftar CPNS.</li>
               <br/>
               <li>trikcpns.com berdiri sendiri sebagai platform belajar independen dan bukan tempat pendaftaran CPNS</li>
               <br/>
               <li>Seluruh fasilitas yang disediakan di dalam website trikcpns.com bersifat membantu proses belajar bagi calon CPNS dan tidak ada tujuan untuk merugikan pihak manapun, karena sifatnya terbuka jika ingin menggunakan fasilitas yang ada di website trikCPNS.com</li>
               <br/>
               <li>trikcpns tidak bertanggung jawab atas penyalahgunaan informasi dan pengetahuan yang terdapat di dalam website ini.</li>
            </ol>
            </p>
         </div>
      </section>
   </div>
</template>

<script>
export default {
    metaInfo: {
        title: 'Privacy Policy',
        titleTemplate: '%s'
    },
}
</script>
